<template>
  <div class="totalBody">
    <div class="headerBox">
      <p>滁州高维车间{{ lineName }} 烧机/OQC看板</p>
      <div class="currentDate">{{ nowTime }}</div>
    </div>

    <el-row>
      <el-col :span="16">
        <el-row class="rows" :gutter="20">
          <el-col :span="7">
            <div class="box reportBox">
              <h4 class="chart-title">烧机产能状况</h4>
              <div class="tableBox">
                <el-table :data="capacityList">
                  <el-table-column align="center" prop="userName" label="姓名"></el-table-column>
                  <el-table-column align="center" prop="weekCnt" label="本周"></el-table-column>
                  <el-table-column align="center" prop="monthCnt" label="本月"></el-table-column>
                </el-table>
              </div>
            </div>

          </el-col>
          <el-col :span="7">
            <div class="box reportBox">
              <h4 class="chart-title">烧机不良率状况</h4>
              <div class="tableBox">
                <el-table :data="repairRateList">
                  <el-table-column align="center" prop="userName" label="姓名"></el-table-column>
                  <el-table-column align="center" prop="weekCnt" label="本周"></el-table-column>
                  <el-table-column align="center" prop="monthCnt" label="本月"></el-table-column>
                </el-table>
              </div>
            </div>

          </el-col>
          <el-col :span="10">
            <div class="box reportBox">
              <h4 class="chart-title">烧机在库状况</h4>
              <div class="tableBox">
                <el-table :data="inStoreList">
                  <el-table-column align="center" prop="userName" label="姓名"></el-table-column>
                  <el-table-column align="center" prop="totalCnt" label="合计"></el-table-column>
                  <el-table-column align="center" prop="day2Cnt" label="<2天"></el-table-column>
                  <el-table-column align="center" prop="day3Cnt" label="<3天"></el-table-column>
                  <el-table-column align="center" prop="day3MoreCnt" label=">3天"></el-table-column>
                </el-table>
              </div>
            </div>

          </el-col>
        </el-row>

        <el-row class="rows" :gutter="20">
          <el-col :span="7">
            <div class="box reportBox">
              <h4 class="chart-title">OQC产能状况</h4>
              <div class="tableBox">
                <el-table :data="capacityList">
                  <el-table-column align="center" prop="userName" label="姓名"></el-table-column>
                  <el-table-column align="center" prop="weekCnt" label="本周"></el-table-column>
                  <el-table-column align="center" prop="monthCnt" label="本月"></el-table-column>
                </el-table>
              </div>
            </div>

          </el-col>
          <el-col :span="7">
            <div class="box reportBox">
              <h4 class="chart-title">OQC不良率状况</h4>
              <div class="tableBox">
                <el-table :data="repairRateList">
                  <el-table-column align="center" prop="userName" label="姓名"></el-table-column>
                  <el-table-column align="center" prop="weekCnt" label="本周"></el-table-column>
                  <el-table-column align="center" prop="monthCnt" label="本月"></el-table-column>
                </el-table>
              </div>
            </div>

          </el-col>
          <el-col :span="10">
            <div class="box reportBox">
              <h4 class="chart-title">OQC在库状况</h4>
              <div class="tableBox">
                <el-table :data="inStoreList">
                  <el-table-column align="center" prop="userName" label="姓名"></el-table-column>
                  <el-table-column align="center" prop="totalCnt" label="合计"></el-table-column>
                  <el-table-column align="center" prop="day2Cnt" label="<2天"></el-table-column>
                  <el-table-column align="center" prop="day3Cnt" label="<3天"></el-table-column>
                  <el-table-column align="center" prop="day3MoreCnt" label=">3天"></el-table-column>
                </el-table>
              </div>
            </div>

          </el-col>
        </el-row>
      </el-col>
      <el-col :span="8" style="padding-right: 20px">
        <div class="box trouble-box">
          <h4 class="chart-title">异常状况</h4>
          <div class="tableBox">
            <el-table :data="troubleList">
              <el-table-column align="center" prop="customer" label="客户"></el-table-column>
              <el-table-column align="center" prop="productName" label="产品"></el-table-column>
              <el-table-column align="center" prop="troubleDesc" label="异常描述"></el-table-column>
              <el-table-column align="center" prop="processor" label="处理负责人" width="115"></el-table-column>
              <el-table-column align="center" prop="finishTime" label="预计完成时间" width="135"></el-table-column>
            </el-table>
          </div>
        </div>
      </el-col>
    </el-row>


  </div>
</template>

<script>

import {currentDate} from "@/utils/common";

export default {
  name: "BurnOqcScreen",
  data() {
    return {
      nowTime: currentDate(),
      capacityList: [
        {userName: '人员1', weekCnt: 50, monthCnt: 180},
        {userName: '人员2', weekCnt: 50, monthCnt: 180},
        {userName: '人员3', weekCnt: 50, monthCnt: 180},
        {userName: '人员4', weekCnt: 50, monthCnt: 180},
        {userName: '人员5', weekCnt: 50, monthCnt: 180},
        {userName: '人员6', weekCnt: 50, monthCnt: 180},
        {userName: '人员7', weekCnt: 50, monthCnt: 180},
        {userName: '人员8', weekCnt: 50, monthCnt: 180},
        {userName: '人员9', weekCnt: 50, monthCnt: 180},
      ],
      repairRateList: [
        {userName: '人员1', weekCnt: '96%', monthCnt: '98%'},
        {userName: '人员2', weekCnt: '96%', monthCnt: '98%'},
        {userName: '人员3', weekCnt: '96%', monthCnt: '98%'},
        {userName: '人员4', weekCnt: '96%', monthCnt: '98%'},
        {userName: '人员5', weekCnt: '96%', monthCnt: '98%'},
        {userName: '人员6', weekCnt: '96%', monthCnt: '98%'},
        {userName: '人员7', weekCnt: '96%', monthCnt: '98%'},
        {userName: '人员8', weekCnt: '96%', monthCnt: '98%'},
        {userName: '人员9', weekCnt: '96%', monthCnt: '98%'},
      ],
      inStoreList: [
        {userName: '人员1', totalCnt: 10, day2Cnt: 5, day3Cnt: 3, day3MoreCnt: 2},
        {userName: '人员2', totalCnt: 10, day2Cnt: 5, day3Cnt: 3, day3MoreCnt: 2},
        {userName: '人员3', totalCnt: 10, day2Cnt: 5, day3Cnt: 3, day3MoreCnt: 2},
        {userName: '人员4', totalCnt: 10, day2Cnt: 5, day3Cnt: 3, day3MoreCnt: 2},
        {userName: '人员5', totalCnt: 10, day2Cnt: 5, day3Cnt: 3, day3MoreCnt: 2},
        {userName: '人员6', totalCnt: 10, day2Cnt: 5, day3Cnt: 3, day3MoreCnt: 2},
      ],
      overTimeList: [
        {userName: '人员1', customer: '联想宝龙达', sn: '8SSB20N59949S1SZ0AV10DE'},
        {userName: '人员2', customer: '联想宝龙达', sn: '8SSB20N59949S1SZ0AV10DE'},
        {userName: '人员3', customer: '联想宝龙达', sn: '8SSB20N59949S1SZ0AV10DE'},
        {userName: '人员4', customer: '联想宝龙达', sn: '8SSB20N59949S1SZ0AV10DE'},
        {userName: '人员5', customer: '联想宝龙达', sn: '8SSB20N59949S1SZ0AV10DE'},
      ],
      troubleList: [
        {customer: '联想宝龙达', productName: '主板', troubleDesc: '脱焊', processor: '张六伟', finishTime: '2023-01-16'},
        {customer: '联想宝龙达', productName: '主板', troubleDesc: '脱焊', processor: '张六伟', finishTime: '2023-01-16'},
        {customer: '联想宝龙达', productName: '主板', troubleDesc: '脱焊', processor: '张六伟', finishTime: '2023-01-16'},
        {customer: '联想宝龙达', productName: '主板', troubleDesc: '脱焊', processor: '张六伟', finishTime: '2023-01-16'},
        {customer: '联想宝龙达', productName: '主板', troubleDesc: '脱焊', processor: '张六伟', finishTime: '2023-01-16'},
        {customer: '联想宝龙达', productName: '主板', troubleDesc: '脱焊', processor: '张六伟', finishTime: '2023-01-16'}
      ],
      lineMap: {
        line1: '一线',
        line2: '二线',
      },
      lineName: ''

    }
  },
  mounted() {
    document.title = '烧机/OQC看板'

    let param = this.$route.params.lineCode;
    this.lineName = this.lineMap[param]

    setInterval(() => this.nowTime = currentDate(), 1000);

  },
}
</script>

<style scoped>
.totalBody {
  color: #ffffff;
  min-width: 1900px;
  min-height: 1080px;
  background: url('/report/bg_report.png') no-repeat;
  background-size: cover; /* 拉伸图片覆盖整个区域 */
  padding: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.headerBox {
  width: 100%;
  height: 100px;
  background: url('/report/header.png') no-repeat;
  background-size: 100% 100%;
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
}

p {
  margin: 0 0 10px;
  padding-top: 5px;
}

.headerBox > p {
  text-align: center;
  font-size: 36px;
  font-weight: bold;
}

.headerBox > div {
  position: absolute;
  top: 0;
}


.lineName {
  font-size: 18px;
  right: 200px;
  line-height: 35px;
}

.currentDate {
  font-size: 14px;
  right: 30px;
  line-height: 35px;
}

.rows {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
}

.chart-title {
  font-weight: normal;
  font-size: 24px;
  margin-top: 0px;
  margin-bottom: 10px;
  text-align: center;
  color: #01c4f7;
}

.chart-title:before {
  content: url('/report/title_before.png');
}

.chart-title:after {
  content: url('/report/title_after.png');
}

.box {
  background-image: url('/report/top_left.png'), url('/report/bottom_left.png'), url('/report/top_right.png'), url('/report/bottom_right.png'), url('/report/report_bg.png');
  background-repeat: no-repeat;
  background-position: top left, bottom left, top right, bottom right, top left;
  background-size: 30px 30px, 30px 30px, 30px 30px, 30px 30px, 100% 100%;
}

.reportBox {
  height: 456px;
}

.trouble-box {
  height: 930px;
}

.tableBox /deep/ .el-table tr {
  background-color: transparent;
  border: none;
}

.tableBox /deep/ .el-table th, .el-table tr, .el-table td {
  background-color: transparent;
  border-style: none;
}

.tableBox /deep/ table tbody tr:nth-child(odd) {
  background: rgba(30, 176, 252, .3);
  border: none;
}

.tableBox /deep/ .el-table, .el-table__expanded-cell {
  background-color: transparent;
  border: none;
}

.tableBox /deep/ .el-table--enable-row-transition .el-table__body td, .el-table .cell {
  background-color: transparent;
  border: none;
  padding-top: 7px;
  padding-bottom: 7px;
}

.tableBox /deep/ .el-table td, thead {
  color: #1eb0fc;
  font-size: 18px;
}

.tableBox /deep/ .el-table th, thead {
  color: #1eb0fc;
  font-size: 18px;
  padding: 5px 0;
}

.el-table--border::after, .el-table--group::after, .el-table::before {
  display: none;
}

.tableBox /deep/ .el-table__inner-wrapper::before {
  content: none;
}

.aaa {
  font-size: 28px;
  width: 140px;
  text-align: center;
}

</style>